<template>
  <div id="airChangeRecord">
    <el-dialog
      :title="airChangeRecordFormTitle"
      width="1200px"
      :visible.sync="airChangeRecordDialogVisible"
      :close-on-click-modal="false"
      @close="airChangeRecordDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="airChangeRecordFormRef"
        :model="airChangeRecordForm"
        :rules="airChangeRecordFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="测试状态" prop="testStatus">
              <el-input
                v-model="airChangeRecordForm.testStatus"
                placeholder="请输入测试状态"
                clearable
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="airChangeRecordForm.deviceName"
                placeholder="请输入设备名称"
                clearable
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-input
                v-model="airChangeRecordForm.deviceNo"
                placeholder="请输入设备编号"
                clearable
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="标准依据" prop="standard">
              <el-input
                v-model="airChangeRecordForm.standard"
                readonly
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试日期" prop="testDate">
              <el-date-picker
                v-model="airChangeRecordForm.testDate"
                placeholder="请选择测试日期"
                value-format="yyyy-MM-dd"
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="airChangeDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="area"
              title="测试区域"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="areaVolume"
              title="区域容积m³"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="windSpeed"
              title="测量风速数据（m/s）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="airChangesTimes"
              title="换气次数（次/h）"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="standard"
              title="判定标准"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="conclusion"
              title="结论"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: 1, label: '合格' },
                  { value: 2, label: '不合格' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="airChangeRecordForm.remark"
                readonly
                :disabled="airChangeRecordFormTitle !== '新增换气次数检测记录'
                  && airChangeRecordFormTitle !== '修改换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="airChangeRecordForm.reviewResult"
                :disabled="airChangeRecordFormTitle !== '复核换气次数检测记录'
                  && airChangeRecordFormTitle !== '换气次数检测记录详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="airChangeRecordDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="airChangeRecordFormSubmit">
          确 定
        </el-button>
        <el-button v-if="airChangeRecordFormTitle === '换气次数检测记录详情'" type="primary" @click="getPdf">
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="测试日期">
        <el-date-picker v-model="searchForm.testDate" placeholder="请选择测试日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['AIR_CHANGE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airChangeRecordPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="testStatus" label="测试状态" />
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column prop="standard" label="标准依据" />
      <el-table-column label="测试日期">
        <template slot-scope="scope">
          <span v-if="scope.row.testDate">{{ scope.row.testDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" />
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectDate">{{ scope.row.inspectDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['AIR_CHANGE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['AIR_CHANGE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['AIR_CHANGE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airChangeRecordPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addAirChangeRecord,
  deleteAirChangeRecord,
  updateAirChangeRecord,
  selectAirChangeRecordInfo,
  selectAirChangeRecordList,
  reviewAirChangeRecord
} from '@/api/quality/airChangeRecord'
import html2PDF from 'jspdf-html2canvas'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      airChangeRecordDialogVisible: false,
      airChangeRecordFormTitle: '',
      airChangeRecordForm: {
        id: '',
        testStatus: '',
        deviceName: '',
        deviceNo: '',
        standard: 'YY/T 0033-2000',
        testDate: '',
        remark: '换气次数=平均风速×送风口面积×3600÷区域容积',
        reviewResult: '',
        status: '',
        airChangeDetailJson: '',
        taskId: ''
      },
      airChangeRecordFormRules: {
        testDate: [{ required: true, message: '测试日期不能为空', trigger: ['blur', 'change']}]
      },
      airChangeRecordPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        testDate: ''
      },
      airChangeDetailList: [
        // { area: '女一更', areaVolume: '14.10', standard: '≥15次/h', conclusion: '' },
        // { area: '男一更', areaVolume: '11.99', standard: '≥15次/h', conclusion: '' },
        { area: '女二更', areaVolume: '14.10', standard: '≥15次/h', conclusion: '' },
        { area: '男二更', areaVolume: '11.99', standard: '≥15次/h', conclusion: '' },
        { area: '缓冲间', areaVolume: '15.98', standard: '≥15次/h', conclusion: '' },
        { area: '洗衣间', areaVolume: '7.05', standard: '≥15次/h', conclusion: '' },
        { area: '器具清洗间', areaVolume: '7.05', standard: '≥15次/h', conclusion: '' },
        { area: '清洗间', areaVolume: '46.06', standard: '≥15次/h', conclusion: '' },
        { area: '暂存间', areaVolume: '19.74', standard: '≥15次/h', conclusion: '' },
        { area: '组装车间', areaVolume: '244.40', standard: '≥15次/h', conclusion: '' },
        { area: '焊接间', areaVolume: '37.37', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间二更', areaVolume: '3.88', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间缓冲间', areaVolume: '4.58', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间', areaVolume: '9.87', standard: '≥15次/h', conclusion: '' },
        { area: '二更', areaVolume: '3.95', standard: '≥15次/h', conclusion: '' },
        { area: '无菌室缓冲间', areaVolume: '4.91', standard: '≥15次/h', conclusion: '' },
        { area: '微生物限度室缓冲间', areaVolume: '4.91', standard: '≥15次/h', conclusion: '' },
        { area: '无菌室', areaVolume: '10.34', standard: '≥15次/h', conclusion: '' },
        { area: '微生物限度室', areaVolume: '10.11', standard: '≥15次/h', conclusion: '' }
      ]
    }
  },
  created () {
    selectAirChangeRecordList(this.searchForm).then(res => {
      this.airChangeRecordPage = res
    })
  },
  methods: {
    airChangeRecordDialogClose () {
      this.$refs.airChangeRecordFormRef.resetFields()
      this.airChangeDetailList = [
        // { area: '女一更', areaVolume: '14.10', standard: '≥15次/h', conclusion: '' },
        // { area: '男一更', areaVolume: '11.99', standard: '≥15次/h', conclusion: '' },
        { area: '女二更', areaVolume: '14.10', standard: '≥15次/h', conclusion: '' },
        { area: '男二更', areaVolume: '11.99', standard: '≥15次/h', conclusion: '' },
        { area: '缓冲间', areaVolume: '15.98', standard: '≥15次/h', conclusion: '' },
        { area: '洗衣间', areaVolume: '7.05', standard: '≥15次/h', conclusion: '' },
        { area: '器具清洗间', areaVolume: '7.05', standard: '≥15次/h', conclusion: '' },
        { area: '清洗间', areaVolume: '46.06', standard: '≥15次/h', conclusion: '' },
        { area: '暂存间', areaVolume: '19.74', standard: '≥15次/h', conclusion: '' },
        { area: '组装车间', areaVolume: '244.40', standard: '≥15次/h', conclusion: '' },
        { area: '焊接间', areaVolume: '37.37', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间二更', areaVolume: '3.88', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间缓冲间', areaVolume: '4.58', standard: '≥15次/h', conclusion: '' },
        { area: '阳性间', areaVolume: '9.87', standard: '≥15次/h', conclusion: '' },
        { area: '二更', areaVolume: '3.95', standard: '≥15次/h', conclusion: '' },
        { area: '无菌室缓冲间', areaVolume: '4.91', standard: '≥15次/h', conclusion: '' },
        { area: '微生物限度室缓冲间', areaVolume: '4.91', standard: '≥15次/h', conclusion: '' },
        { area: '无菌室', areaVolume: '10.34', standard: '≥15次/h', conclusion: '' },
        { area: '微生物限度室', areaVolume: '10.11', standard: '≥15次/h', conclusion: '' }
      ]
    },
    airChangeRecordFormSubmit () {
      if (this.airChangeRecordFormTitle === '换气次数检测记录详情') {
        this.airChangeRecordDialogVisible = false
        return
      }
      this.$refs.airChangeRecordFormRef.validate(async valid => {
        if (valid) {
          if (this.airChangeRecordFormTitle === '新增换气次数检测记录') {
            this.airChangeRecordForm.id = ''
            this.airChangeRecordForm.status = 1
            this.airChangeRecordForm.airChangeDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addAirChangeRecord(this.airChangeRecordForm)
          } else if (this.airChangeRecordFormTitle === '修改换气次数检测记录') {
            this.airChangeRecordForm.airChangeDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateAirChangeRecord(this.airChangeRecordForm)
          } else if (this.airChangeRecordFormTitle === '复核换气次数检测记录') {
            this.airChangeRecordForm.status = 2
            await reviewAirChangeRecord(this.airChangeRecordForm)
          }
          this.airChangeRecordPage = await selectAirChangeRecordList(this.searchForm)
          this.airChangeRecordDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.airChangeRecordFormTitle = '新增换气次数检测记录'
      this.airChangeRecordDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAirChangeRecord(row.id)
        if (this.airChangeRecordPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.airChangeRecordPage = await selectAirChangeRecordList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.airChangeRecordFormTitle = '修改换气次数检测记录'
      this.airChangeRecordDialogVisible = true
      this.selectAirChangeRecordInfoById(row.id)
    },
    handleReview (index, row) {
      this.airChangeRecordFormTitle = '复核换气次数检测记录'
      this.airChangeRecordDialogVisible = true
      this.selectAirChangeRecordInfoById(row.id)
    },
    handleInfo (index, row) {
      this.airChangeRecordFormTitle = '换气次数检测记录详情'
      this.airChangeRecordDialogVisible = true
      this.selectAirChangeRecordInfoById(row.id)
    },
    selectAirChangeRecordInfoById (id) {
      selectAirChangeRecordInfo(id).then(res => {
        this.airChangeRecordForm.id = res.id
        this.airChangeRecordForm.testStatus = res.testStatus
        this.airChangeRecordForm.deviceName = res.deviceName
        this.airChangeRecordForm.deviceNo = res.deviceNo
        this.airChangeRecordForm.standard = res.standard
        this.airChangeRecordForm.testDate = res.testDate
        this.airChangeRecordForm.remark = res.remark
        this.airChangeRecordForm.reviewResult = res.reviewResult
        this.airChangeRecordForm.status = res.status
        this.airChangeRecordForm.taskId = res.taskId
        this.airChangeDetailList = res.airChangeDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAirChangeRecordList(this.searchForm).then(res => {
        this.airChangeRecordPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAirChangeRecordList(this.searchForm).then(res => {
        this.airChangeRecordPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAirChangeRecordList(this.searchForm).then(res => {
        this.airChangeRecordPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['AIR_CHANGE_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `换气次数检测记录${this.airChangeRecordForm.testDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
