import axios from '@/common/axios'
import qs from 'qs'

export function addAirChangeRecord (data) {
  return axios({
    method: 'post',
    url: '/quality/airChange/add',
    data: qs.stringify(data)
  })
}

export function deleteAirChangeRecord (id) {
  return axios({
    method: 'delete',
    url: '/quality/airChange/delete/' + id
  })
}

export function updateAirChangeRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/airChange/update',
    data: qs.stringify(data)
  })
}

export function selectAirChangeRecordInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/airChange/info/' + id
  })
}

export function selectAirChangeRecordList (query) {
  return axios({
    method: 'get',
    url: '/quality/airChange/list',
    params: query
  })
}

export function reviewAirChangeRecord (data) {
  return axios({
    method: 'put',
    url: '/quality/airChange/updateReview',
    data: qs.stringify(data)
  })
}
